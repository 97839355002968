import { Button, Link as AriaLink, PressEvent } from 'react-aria-components'
import styled from 'styled-components'
import { DSFlex } from '../Flex/Flex'
import Image from 'next/image'
import { DSSpacer } from '../Spacer/Spacer'
import { DSDivider, DSIcon, DSText } from '@zoe/ds-web'
import { DSButton } from '../Button/Button'
import { useEffect, useState } from 'react'
import { BREAKPOINTS } from '../breakpoints'
import { DSShow } from '../Responsive/Show/Show'
import { DSHide } from '../Responsive/Hide/Hide'
import { useRouter } from 'next/router'
import { BANNER_HEIGHT_DESKTOP, BANNER_HEIGHT_MOBILE } from './Banner'

const StyledNavigation = styled.nav<{ $isScrolled: boolean; $showBanner: boolean }>`
  background-color: ${({ $isScrolled }) => ($isScrolled ? 'var(--color-surface-neutral)' : 'transparent')};
  padding: var(--grid-16) var(--grid-24);
  transition: background-color 0.2s ease-in-out;
  position: fixed;
  top: ${({ $showBanner }) => ($showBanner ? `${BANNER_HEIGHT_DESKTOP}px` : '0')};
  right: 0;
  width: 100vw;
  max-width: 100%;
  z-index: 4;
  @media screen and (min-width: ${BREAKPOINTS.l}) {
    padding: var(--grid-24) var(--grid-72);
    height: 88px;
  }
  @media screen and (max-width: ${BREAKPOINTS.s}) {
    top: ${({ $showBanner }) => ($showBanner ? `${BANNER_HEIGHT_MOBILE}px` : '0')};
  }
`

const StyledCTAContainer = styled.div<{ $isScrolled: boolean }>`
  opacity: ${({ $isScrolled }) => ($isScrolled ? '1' : '0')};
  transition: opacity 0.2s ease-in-out;
`

const StyledMenu = styled.div<{ $open: boolean }>`
  transition: min-height 1.2s cubic-bezier(0.5, 1, 0, 1);
  text-align: center;

  @media screen and (max-width: ${BREAKPOINTS.l}) {
    width: 100vw;
    background: var(--color-surface-neutral);
    position: absolute;
    top: 0;
    left: 0;
    height: 0;
    overflow: hidden;
    min-height: ${({ $open }) => ($open ? '100vh' : '0')};
    z-index: 1;
    padding: 0 var(--grid-16);
  }
`
const StyledAriaLink = styled(AriaLink)<{ $isActive: boolean }>`
  position: relative;
  display: flex;
  align-items: flex-start;

  span {
    color: var(--color-text-link-navigation-bold);
    transition: color 0.2s ease-in-out;
    font-family: ${({ $isActive }) => $isActive && 'var(--font-sans-serif-regular), var(--font-fallback-sans-serif)'};
  }

  &[data-hovered] {
    span {
      color: var(--color-text-link-navigation-bold-hover);
    }
  }

  @media screen and (max-width: ${BREAKPOINTS.l}) {
    padding: 0 var(--grid-16);
  }
`

const StyledImage = styled(Image)`
  z-index: 2;
  width: 90px;
  min-width: 70px;

  @media screen and (max-width: ${BREAKPOINTS.l}) {
    width: 68px;
    min-width: 68px;
  }
`

const StyledLogoLink = styled(AriaLink)`
  z-index: 2;
`

const StyledCloseButton = styled(Button)`
  position: absolute;
  z-index: 2;
  border: 0;
  background: transparent;
  display: flex;
  top: var(--grid-16);
  right: var(--grid-16);
  cursor: pointer;
`

const StyledBadge = styled.small`
  font-size: 10px;
  text-transform: uppercase;
  color: var(--color-text-primary);
  padding: 0 var(--grid-4);
  background-color: hsla(163, 30%, 79%, 1);
  border-radius: var(--radius-4);
  font-family: var(--font-sans-serif-semibold);
  line-height: 1.5;
  &[data-hovered] {
    opacity: 1;
  }
`

const StyledJoinButton = styled.div<{ $isScrolled: boolean; $isSMenuOpen: boolean }>`
  opacity: ${({ $isScrolled, $isSMenuOpen }) => ($isScrolled || $isSMenuOpen ? '1' : '0')};
  transition: opacity 0.2s ease-in-out;
  z-index: 2;
  display: flex;
`

const StyledLinksContainer = styled.div`
  display: flex;
  gap: var(--grid-24);
  @media screen and (max-width: ${BREAKPOINTS.l}) {
    max-height: 70vh;
    overflow-y: scroll;
    flex-direction: column;
    gap: 0;
  }
`

interface CustomNavigationProps {
  links: {
    text: string
    href: string
    onPress?: () => void
    badge?: string
  }[]
  logoVariant: 'brand' | 'primary'
  menuLabel: string
  ctaText?: string
  ctaHref?: string
  onCtaPress?: (event: PressEvent) => void
  onMenuPress?: (event: PressEvent) => void
  onLogoPress?: (event: PressEvent) => void
  withTopBanner?: boolean
}

export const DSNavigation = ({
  links,
  logoVariant,
  ctaText = 'Join ZOE',
  ctaHref = '/quiz',
  onCtaPress,
  onMenuPress,
  menuLabel,
  onLogoPress,
  withTopBanner,
}: CustomNavigationProps) => {
  const [isScrolled, setIsScrolled] = useState(false)
  const [isSMenuOpen, setIsSMenuOpen] = useState(false)
  const [ctaId, setCtaId] = useState('')

  const router = useRouter()

  const checkScrollTop = () => {
    setIsScrolled(window.scrollY > 0)
  }

  useEffect(() => {
    window.addEventListener('scroll', checkScrollTop)
    return () => window.removeEventListener('scroll', checkScrollTop)
  }, [])

  useEffect(() => {
    setCtaId(ctaText?.toLowerCase()?.replace(/ /g, '-'))
  }, [ctaText])

  const handleCtaOnPress = (event: PressEvent) => {
    if (onCtaPress) {
      onCtaPress(event)
    }
  }
  const isActive = (href) => router.pathname === href

  const handleMenuToggle = (event: PressEvent) => {
    setIsSMenuOpen(!isSMenuOpen)
    onMenuPress && onMenuPress(event)
  }

  return (
    <StyledNavigation $isScrolled={isScrolled} $showBanner={!!withTopBanner} aria-label="Main navigation" id="main-nav">
      <DSFlex direction="row" alignItems="center" justifyContent="space-between">
        <DSFlex direction="row" alignItems="center">
          <StyledLogoLink href="/" onPress={onLogoPress}>
            <StyledImage src={`/assets/img/ds/zoe-logo-${logoVariant}.svg`} alt="ZOE logo" width={90} height={32} />
          </StyledLogoLink>
          <DSHide below="l">
            <DSSpacer size={48} direction="horizontal" />
          </DSHide>
          <StyledMenu $open={isSMenuOpen}>
            <DSShow below="l">
              <DSSpacer direction="vertical" size={96} />
              <DSDivider spacing={16} />
            </DSShow>
            <StyledLinksContainer>
              {links.map((link, index) => (
                <div key={index} id={`nav-link-${link.text?.toLowerCase()?.replace(/ /g, '-')}`}>
                  <StyledAriaLink href={link.href} onPress={link.onPress} $isActive={isActive(link.href)}>
                    <DSText as="span" variant="fluid-paragraph-300" weight="light" color="primary">
                      {link.text}
                    </DSText>
                    {link.badge && <DSSpacer direction="horizontal" size={8} />}
                    {link.badge && <StyledBadge>{link.badge}</StyledBadge>}
                  </StyledAriaLink>
                  <DSShow below="l">
                    <DSDivider spacing={16} />
                  </DSShow>
                </div>
              ))}
            </StyledLinksContainer>
            {!!(onCtaPress && ctaHref?.length && ctaText?.length && isSMenuOpen) && (
              <>
                <DSSpacer direction="vertical" size={24} />
                <StyledJoinButton $isScrolled={isScrolled} $isSMenuOpen={isSMenuOpen}>
                  <a href={ctaHref} aria-label={ctaText} id={`navigation-cta-${ctaId}-mobile`}>
                    <DSButton
                      variant="primary"
                      colourScheme="default"
                      size="large"
                      width="auto"
                      onPress={handleCtaOnPress}
                      style={{ textWrap: 'nowrap' }}
                      testId={`navigation-cta-${ctaId}`}
                    >
                      <DSText as="span" variant="fluid-paragraph-200" weight="semiBold" color="primary">
                        {ctaText}
                      </DSText>
                    </DSButton>
                  </a>
                </StyledJoinButton>
              </>
            )}
          </StyledMenu>
        </DSFlex>
        <DSFlex direction="row" gap={12} alignItems="center">
          {!!(onCtaPress && ctaHref?.length && ctaText?.length) && (
            <StyledCTAContainer $isScrolled={isScrolled}>
              <DSSpacer direction="horizontal" size={12} />
              <a href={ctaHref} aria-label={ctaText} id={`navigation-cta-${ctaId}-desktop`}>
                <DSButton
                  variant="primary"
                  colourScheme="default"
                  size="small"
                  width="auto"
                  onPress={handleCtaOnPress}
                  style={{ textWrap: 'nowrap' }}
                  testId={`navigation-cta-${ctaId}-desktop`}
                >
                  <DSText as="span" variant="fluid-paragraph-200" weight="semiBold" color="primary">
                    {ctaText}
                  </DSText>
                </DSButton>
              </a>
            </StyledCTAContainer>
          )}
          {isSMenuOpen ? (
            <StyledCloseButton onPress={handleMenuToggle}>
              <DSIcon name="close" color="primary" size={32} />
            </StyledCloseButton>
          ) : (
            <DSHide above="l">
              <DSButton variant="secondary" size="small" colourScheme="default" width="auto" onPress={handleMenuToggle}>
                <DSText as="span" variant="fluid-paragraph-200" weight="semiBold" color="primary">
                  {menuLabel}
                </DSText>
              </DSButton>
            </DSHide>
          )}
        </DSFlex>
      </DSFlex>
    </StyledNavigation>
  )
}
